body {
  overflow-x: hidden;
}
.WhyLogo {
  background-color: aliceblue;
  width: 1130px;
  max-width: 95%;
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
}

.WhyLogo img {
  width: 40%;
  height: 40%;
  /* margin-right: 50px; */
}

.Why-text h3 {
  text-align: left;
  font-size: 40px;
  margin-left: 20px;
  color: #ea9999;
  /* text-transform: capitalize; */
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  padding-right: 10px;
  /* font-family: "Playfair Display", serif; */
  font-family: "Lato", sans-serif;
  font-weight: 600;
  /* font-family: "Open Sans", sans-serif; */
}
.Why-text p {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  margin-left: 20px;
  padding-right: 10px;
  /* font-family: "Playfair Display", serif; */

  /* text-align: justify; */
}

.heading-logo span {
  /* font-size: 35px; */
  color: hsl(7, 86%, 31%) 100%;
}
.sliderimg {
  background-color: aliceblue;
  width: 60%;
  /* max-width: 95%; */
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
}

@media screen and (max-width: 960px) {
  .WhyLogo {
    background-color: aliceblue;
    width: 1130px;
    max-width: 95%;
    margin: 20px auto;
    /* margin-left: 20px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    overflow-x: hidden;
  }
  .Why-text h3 {
    text-align: left;
    font-size: 23px;
    margin-left: 20px;
    color: #ea9999;
    /* text-transform: capitalize; */
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding-right: 10px;
    /* font-family: "Playfair Display", serif; */
    font-family: "Lato", sans-serif;
    font-weight: 600;
    /* font-family: "Open Sans", sans-serif; */
  }
  .Why-text p {
    color: #666;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-left: 20px;
    padding-right: 10px;
    /* font-family: "Playfair Display", serif; */
  
    /* text-align: justify; */
  }
}

