video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}
.hero-container {
  /* background: url("./images/women.jpg") center center/cover no-repeat; */

  /* background: url("https://homepages.cae.wisc.edu/~ece533/images/girl.png")
    center center/cover no-repeat; */
  /* https://homepages.cae.wisc.edu/~ece533/images/monarch.png */
  /* https://homepages.cae.wisc.edu/~ece533/images/girl.png */
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}
.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}
.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.hero-btns {
  margin-top: 32px;
}
.hero-btns .btns {
  margin: 6px;
}
.fa-play-circle {
  margin: left 4px;
}
.linked {
  color: #fff;
}
.linked:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  color: #000000;
}
.yt {
  margin-left: 10px;
  /* padding: 10px; */
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
  .hero-container {
    /* background: url("./images/women.jpg") center center/cover no-repeat; */

    /* background: url("https://homepages.cae.wisc.edu/~ece533/images/girl.png")
      center center/cover no-repeat; */
    /* https://homepages.cae.wisc.edu/~ece533/images/monarch.png */
    /* https://homepages.cae.wisc.edu/~ece533/images/girl.png */
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .hero-container {
    /* background: url("./images/women.jpg") center center/cover no-repeat; */

    /* background: url("https://homepages.cae.wisc.edu/~ece533/images/girl.png")
      center center/cover no-repeat; */
    /* https://homepages.cae.wisc.edu/~ece533/images/monarch.png */
    /* https://homepages.cae.wisc.edu/~ece533/images/girl.png */
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    overflow-x: hidden;
  }

  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .hero-container > p {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
    text-align: center;
    font-size: 30px;
    margin-top: 8px;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}
