.hero {
  /* background: url("./") center center/cover no-repeat; */
  background: #ea9999;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  /* margin-bottom: 30px; */
}
.about {
  /* font-family: OpenSans; */
  font-size: 5rem;
  color: white;
  margin: 29px;
}
.container {
  margin: 29px;
  justify-content: center;
}
.title {
  font-weight: bold;
  text-align: center;
  font-family: Lato;
  letter-spacing: 1px;
  line-height: 80px;
  font-size: 24px;
  text-justify: auto;
  margin: 10px;
  margin-bottom: 10%;
}
.contact {
  text-align: center;
  font-family: Lato;
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 24px;
  text-justify: auto;
  margin-left: 40px;
  margin: 10%;
}
