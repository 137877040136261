@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Lato',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overflow-x: hidden;
}
.navbar {
  background: linear-gradient(
    90deg,
    hsl(7, 86%, 31%) 0%,
    hsl(7, 86%, 31%) 100%
  );
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  /* overflow-x: hidden; */
}

.navbar-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  width: 100%;
  overflow: hidden;
  /* max-width: 100%; */
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  font-family: Playfair;
  overflow: hidden;
  margin-bottom: 20px;
  /* width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  /* margin-top: -6px; */
  /* overflow:scroll; */
  /* overflow: hidden; */
}
/* .navbar-logo > div {
  margin-bottom: 20px;
} */
*/ .fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  /* margin-right: 2rem; */
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  margin-top: -5px;
  margin-bottom: 10px;
}
.navbar-logo:hover {
  transition: all 0.2s ease-out;
  color: white;
}
.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  color: white;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  .navbar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80px;
    width: 100%;
    /* max-width: 100%; */
    overflow: hidden;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: Playfair;
    font-size: 1.9rem;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -9%;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: left;
    /* margin: 2rem auto; */
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  /* .navbar-logo:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  } */
  .navbar {
    background: linear-gradient(
      90deg,
      hsl(7, 86%, 31%) 0%,
      hsl(7, 86%, 31%) 100%
    );
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    /* overflow: hidden; */
  }
}

.App {
  text-align: center;
  /* overflow: hidden; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* overflow-x: hidden; */
}



.App-link {
  color: #61dafb;
}
.App-Section {
  font-family: Playfair;
  font-family: Lato;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  overflow-x: hidden;
}
.WhyLogo {
  background-color: aliceblue;
  width: 1130px;
  max-width: 95%;
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
}

.WhyLogo img {
  width: 40%;
  height: 40%;
  /* margin-right: 50px; */
}

.Why-text h3 {
  text-align: left;
  font-size: 40px;
  margin-left: 20px;
  color: #ea9999;
  /* text-transform: capitalize; */
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  padding-right: 10px;
  /* font-family: "Playfair Display", serif; */
  font-family: "Lato", sans-serif;
  font-weight: 600;
  /* font-family: "Open Sans", sans-serif; */
}
.Why-text p {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  margin-left: 20px;
  padding-right: 10px;
  /* font-family: "Playfair Display", serif; */

  /* text-align: justify; */
}

.heading-logo span {
  /* font-size: 35px; */
  color: hsl(7, 86%, 31%) 100%;
}
.sliderimg {
  background-color: aliceblue;
  width: 60%;
  /* max-width: 95%; */
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
}

@media screen and (max-width: 960px) {
  .WhyLogo {
    background-color: aliceblue;
    width: 1130px;
    max-width: 95%;
    margin: 20px auto;
    /* margin-left: 20px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    overflow-x: hidden;
  }
  .Why-text h3 {
    text-align: left;
    font-size: 23px;
    margin-left: 20px;
    color: #ea9999;
    /* text-transform: capitalize; */
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding-right: 10px;
    /* font-family: "Playfair Display", serif; */
    font-family: "Lato", sans-serif;
    font-weight: 600;
    /* font-family: "Open Sans", sans-serif; */
  }
  .Why-text p {
    color: #666;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-left: 20px;
    padding-right: 10px;
    /* font-family: "Playfair Display", serif; */
  
    /* text-align: justify; */
  }
}


video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}
.hero-container {
  /* background: url("./images/women.jpg") center center/cover no-repeat; */

  /* background: url("https://homepages.cae.wisc.edu/~ece533/images/girl.png")
    center center/cover no-repeat; */
  /* https://homepages.cae.wisc.edu/~ece533/images/monarch.png */
  /* https://homepages.cae.wisc.edu/~ece533/images/girl.png */
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}
.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}
.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.hero-btns {
  margin-top: 32px;
}
.hero-btns .btns {
  margin: 6px;
}
.fa-play-circle {
  margin: left 4px;
}
.linked {
  color: #fff;
}
.linked:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  color: #000000;
}
.yt {
  margin-left: 10px;
  /* padding: 10px; */
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
  .hero-container {
    /* background: url("./images/women.jpg") center center/cover no-repeat; */

    /* background: url("https://homepages.cae.wisc.edu/~ece533/images/girl.png")
      center center/cover no-repeat; */
    /* https://homepages.cae.wisc.edu/~ece533/images/monarch.png */
    /* https://homepages.cae.wisc.edu/~ece533/images/girl.png */
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .hero-container {
    /* background: url("./images/women.jpg") center center/cover no-repeat; */

    /* background: url("https://homepages.cae.wisc.edu/~ece533/images/girl.png")
      center center/cover no-repeat; */
    /* https://homepages.cae.wisc.edu/~ece533/images/monarch.png */
    /* https://homepages.cae.wisc.edu/~ece533/images/girl.png */
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    overflow-x: hidden;
  }

  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .hero-container > p {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
    text-align: center;
    font-size: 30px;
    margin-top: 8px;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}

.footer-container {
  background-color: #931b0b;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.credits{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: rgb(255, 255, 255);
}

.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}
.footer-subscription-text2 {
  margin-bottom: 24px;
  font-size: 15px;
  color:rgb(255, 255, 255);
  justify-content: center;
  /* padding: 8px 20px; */
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  /* margin-right: 10px; */
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

Social Icons */
 .social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
  color: #fff;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #fff; */
  width: 240px;
  text-decoration: none;
  color: inherit;
}
.social-icons i{
  /* text-decoration: none; */
color: #fff;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-family: Playfair;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
  .yt{
    flex-direction: column;
    align-items: center;
    
  }
  .footer-container {
    background-color: #931b0b;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

/* @media screen and (max-width: 768px) {
}  */


.video-responsive {
    overflow: hidden;
    padding-bottom: 50.25%;
    position: relative;
    height: 0;
    
  }
  
  .video-responsive iframe {
    /* margin-bottom: -10px; */
    left: 9.5%;
    top: 17%;
    height: 80%;
    width: 80%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
body {
  overflow-x: hidden;
}
.about {
  /* font-family: OpenSans; */
  font-size: 5rem;
  color: white;
  /* margin: 29px */
  margin-left: 10%;
}
.about2 {
  /* font-family: OpenSans; */
  font-size: 4rem;
  color: grey;
  text-align: center;
  margin-left: 5%;
  /* margin: 29px; */
  /* margin-left: 30%; */
}
.hero {
  /* background: url("./") center center/cover no-repeat; */
  background: #ea9999;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.container_about {
  margin: 40px;
  /* justify-content: space-evenly; */
  display: flex;
  flex-direction: column;
}

.container_ec {
  margin: 20px;
  /* justify-content: space-evenly; */
  display: flex;
  flex-direction: column;
}
.mission {
  text-align: justify;

  font-family: Lato;
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 24px;
  /* width: 70%; */
  /* margin-left: 20%; */
}
/* .value-cont {
  background: #ea9999;
  border-radius: 1%;
} */
.values {
  /* margin: 20px; */
  padding: 20px;
  font-family: Playfair;
  font-size: 4rem;
  color: #ea9999;
  letter-spacing: 1px;
  text-align: center;
  margin-left: 5%;
}

.value-cont {
  margin-left: -5%;
}
.section {
  margin-top: -50px;
  width: 100%;
  padding: 78px 0px;
}
.founders img {
  height: auto;
  width: 420px;
}
.About-text {
  width: 550px;
}
.founders {
  width: 1130px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.About-text h1 {
  color: #ea9999;
  font-size: 80px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.About-text h5 {
  color: #931b0b;
  font-size: 25px;
  text-transform: capitalize;
  letter-spacing: 2px;
}
.heading-f {
  text-align: left;
}
span {
  color: #931b0b;
}
.About-text p {
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 18px;
  margin-bottom: 45px;
}
@media screen and (max-width: 960px) {
  .mission {
    text-align: justify;
    font-family: Lato;
    letter-spacing: 1px;
    line-height: 22px;
    font-size: 18px;
    /* width: 70%; */
    /* margin-left: 20%; */
  }
  .values {
    /* margin: 20px; */
    padding: 20px;
    font-family: Playfair;
    font-size: 2.3rem;
    color: #ea9999;
    letter-spacing: 1px;
    text-align: center;
    margin-left: 5%;
  }
  .about2 {
    /* font-family: OpenSans; */
    font-size: 3rem;
    color: grey;
    text-align: center;
    margin-left: 5%;
  }
  .About-text h1 {
    color: #ea9999;
    font-size: 60px;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  .About-text h5 {
    color: #ea9999;
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 2px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .About-text p {
    letter-spacing: 1px;
    line-height: 18px;
    font-size: 14px;
    margin-left: 10px;
  }
  .founders img {
    height: auto;
    width: 250px;
    padding-top: 10px;
  }
}

/* .header {
  margin-top: 60px;
} */
body{
  overflow-x: hidden;
}
.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lora", serif;
  color: #444;
}

.headerTitleSm {
  position: absolute;
  top: 18%;
  font-size: 20px;
}

.headerTitleLg {
  position: absolute;
  top: 30%;
  font-size: 6rem;
  color: azure;
}

.header img{
  /* opacity: 0.9; */
  width: 100%;
  height: 450px;
  object-fit: cover;
}

@media screen and (max-width: 960px) {
  .headerTitleLg {
    position: absolute;
    top: 20%;
    font-size: 3rem;
    color: azure;
  }

  .header img{
    /* opacity: 0.9; */
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}
.hero {
  /* background: url("./") center center/cover no-repeat; */
  background: #ea9999;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  /* margin-bottom: 30px; */
}
.about {
  /* font-family: OpenSans; */
  font-size: 5rem;
  color: white;
  margin: 29px;
}
.container {
  margin: 29px;
  justify-content: center;
}
.title {
  font-weight: bold;
  text-align: center;
  font-family: Lato;
  letter-spacing: 1px;
  line-height: 80px;
  font-size: 24px;
  text-justify: auto;
  margin: 10px;
  margin-bottom: 10%;
}
.contact {
  text-align: center;
  font-family: Lato;
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 24px;
  text-justify: auto;
  margin-left: 40px;
  margin: 10%;
}

body{
    overflow-x: hidden;
  }
.post {
  width: 385px;
  margin: 0px 25px 40px 25px;
  display: flex;
  flex-direction: column;
}

.postImg {
  width: 385px;
  height: 280px;
  object-fit: cover;
  border-radius: 7px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postCat {
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 11px;
  color: #be9656;
  line-height: 19px;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.postTitle {
  /* font-family: "Josefin Sans", Arial, Helvetica, sans-serif; */
  /* font-weight: 900; */
  margin-top: 15px;
  cursor: pointer;
  /* color: black; */
  text-align: center;

}
.link {
  color: #931b0b;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  text-decoration: none;
  text-align: center;
}

.postDate {
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-top: 15px;
}

.postDesc {
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.posts {
  flex: 9 1;
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  justify-content: center;
  align-items: center;
}

body {
  overflow-x: hidden;
}
.hero {
  /* background: url("./") center center/cover no-repeat; */
  background: #ea9999;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  /* margin-bottom: 30px; */
}

.container-impact {
  /* height: 100vh;  */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* .headerim{
  width: 100%;
  height: 100%;
} */

.row-text-center {
  margin: 20px;
  /* justify-content: space-between; */
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.about {
  /* font-family: OpenSans; */
  font-size: 5rem;
  color: white;
  margin: 29px;
}
/* .container {
  margin: 29px;
  justify-content: center;
} */
.title {
  font-weight: bold;
  text-align: center;
  font-family: Lato;
  letter-spacing: 1px;
  line-height: 80px;
  font-size: 24px;
  text-justify: auto;
  margin: 10px;
  /* margin-bottom: 10%; */
}
.contact {
  text-align: center;
  font-family: Lato;
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 20px;
  text-justify: center;
  /* margin-left: 40px; */
  /* margin: 10%; */
  margin: 0%;
  padding: 10px;
  /* text-indent: 0%; */
}
.headerim {
  width: 50%;
  height: 50%;
  margin-top: 0px;
  /* margin-left: -10px; */
  object-fit: cover;
}
.grid {
  margin-left: 15%;
  margin-top: -10px;
  align-self: center;
}
@media screen and (max-width: 960px) {
  .headerim {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    /* margin-left: -10px; */
    object-fit: cover;
  }
  .title {
    font-weight: bold;
    text-align: center;
    font-family: Lato;
    letter-spacing: 1px;
    line-height: 80px;
    font-size: 1rem;
    text-justify: auto;
    /* margin: 10px; */
    /* margin-bottom: 10%; */
  }
  .grid {
    margin-left: -10%;
    margin-top: -10px;
    align-self: center;
    overflow: hidden;
  }
  .contact {
    text-align: center;
    font-family: Lato;
    letter-spacing: 1px;
    line-height: 28px;
    font-size: 0.9rem;
    text-indent: -1px;
    /* text-justify: center; */
    /* margin-left: 40px; */
    /* margin: 10%; */
    margin: 0%;
  }

  .row-text-center {
    margin: 20px;
    /* justify-content: space-between; */
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
}

.gallery {
  -webkit-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
  margin-top: 20px;
}

.gallery .pics {
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery .pics:hover {
  -webkit-filter: opacity(0.8);
          filter: opacity(0.8);
}
figcaption {
  /* position: absolute;
  bottom: 2rem;
  margin: 0 2rem;
  font-size: 1.5em;
  color: white; */
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.75);
  padding: 4px 8px;
  color: white;
  margin: 0;
  font: 20px Sans-Serif;
}

@media screen and (max-width: 768px) {
  .gallery {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
  }

  figcaption {
    position: inherit;
    bottom: inherit;
    margin: 0.25rem 0px;
    font-size: inherit;
    background: rgba(252, 252, 252, 0.75);
    padding: 4px 8px;
    color: rgba(0, 0, 0, 0.75);
  }
}

/* model */

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    -webkit-transform 0.5s ease-in-out;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model.open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
}

.MainHeading {
  font-size: 60px;
  color: #ea9999;
  text-transform: capitalize;
  margin: 20px;
  text-align: center;
}

.singlePostDesc1 {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  margin-left: 50px;
}

.singlePostDesc1::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.BlogPost1 {
  width: 1130px;
  max-width: 95%;
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.heading-f1 {
  text-align: left;
  font-size: 20px;
  margin-left: 50px;
}
.About-text1 h3 {
  color: #ea9999;
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.About-text1 h5 {
  color: #931b0b;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-left: 50px;
}

.singlePostDesc2 {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  /* margin-left: 50px; */
  /* text-align: justify; */
}

.BlogPost2 img {
  width: 100%;
  height: 100%;
}

.singlePostDesc2::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.BlogPost2 {
  width: 1130px;
  max-width: 95%;
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.heading-f2 {
  text-align: left;
  font-size: 20px;
  margin-left: 50px;
}
.About-text2 h3 {
  color: #ea9999;
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
  text-align: center;
}

.About-text2 h5 {
  color: #931b0b;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-left: 50px;
  text-align: center;
}

.singlePostDesc3 {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  margin-left: 50px;
  text-align: justify;
}

.singlePostDesc3::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.BlogPost3 {
  flex-direction: row;
  flex: 1 1;
  width: 1130px;
  max-width: 95%;
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.heading-f3 {
  text-align: left;
  font-size: 20px;
  margin-left: 50px;
}
.About-text3 h3 {
  color: #ea9999;
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.About-text3 h5 {
  color: #931b0b;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-left: 50px;
  text-align: center;
}

.singlePostDesc4 {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  /* margin-left: 50px; */
  /* text-align: justify; */
}

.BlogPost4 img {
  width: 100%;
  height: 100%;
}

.singlePostDesc4::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.BlogPost4 {
  width: 1130px;
  max-width: 95%;
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.heading-f4 {
  text-align: left;
  font-size: 20px;
  margin-left: 50px;
}
.About-text4 h3 {
  color: #ea9999;
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
  text-align: center;
}

.About-text4 h5 {
  color: #931b0b;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-left: 50px;
  text-align: center;
}

.BlogPost5 {
  width: 1130px;
  max-width: 95%;
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.BlogPost5 img {
  width: 70%;
  height: 70%;
}
.singlePostDesc5 {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  /* margin-left: 50px; */
  /* text-align: justify; */
}

.singlePostDesc5::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.heading-f5 {
  text-align: left;
  font-size: 20px;
  /* margin-left: 50px; */
}
.About-text5 h3 {
  color: #ea9999;
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
  text-align: center;
}

.About-text5 h5 {
  color: #931b0b;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-left: 50px;
  text-align: center;
}

