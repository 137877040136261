.singlePostDesc3 {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  margin-left: 50px;
  text-align: justify;
}

.singlePostDesc3::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.BlogPost3 {
  flex-direction: row;
  flex: 1;
  width: 1130px;
  max-width: 95%;
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.heading-f3 {
  text-align: left;
  font-size: 20px;
  margin-left: 50px;
}
.About-text3 h3 {
  color: #ea9999;
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.About-text3 h5 {
  color: #931b0b;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-left: 50px;
  text-align: center;
}
