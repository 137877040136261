body {
  overflow-x: hidden;
}
.about {
  /* font-family: OpenSans; */
  font-size: 5rem;
  color: white;
  /* margin: 29px */
  margin-left: 10%;
}
.about2 {
  /* font-family: OpenSans; */
  font-size: 4rem;
  color: grey;
  text-align: center;
  margin-left: 5%;
  /* margin: 29px; */
  /* margin-left: 30%; */
}
.hero {
  /* background: url("./") center center/cover no-repeat; */
  background: #ea9999;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.container_about {
  margin: 40px;
  /* justify-content: space-evenly; */
  display: flex;
  flex-direction: column;
}

.container_ec {
  margin: 20px;
  /* justify-content: space-evenly; */
  display: flex;
  flex-direction: column;
}
.mission {
  text-align: justify;

  font-family: Lato;
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 24px;
  /* width: 70%; */
  /* margin-left: 20%; */
}
/* .value-cont {
  background: #ea9999;
  border-radius: 1%;
} */
.values {
  /* margin: 20px; */
  padding: 20px;
  font-family: Playfair;
  font-size: 4rem;
  color: #ea9999;
  letter-spacing: 1px;
  text-align: center;
  margin-left: 5%;
}

.value-cont {
  margin-left: -5%;
}
.section {
  margin-top: -50px;
  width: 100%;
  padding: 78px 0px;
}
.founders img {
  height: auto;
  width: 420px;
}
.About-text {
  width: 550px;
}
.founders {
  width: 1130px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.About-text h1 {
  color: #ea9999;
  font-size: 80px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.About-text h5 {
  color: #931b0b;
  font-size: 25px;
  text-transform: capitalize;
  letter-spacing: 2px;
}
.heading-f {
  text-align: left;
}
span {
  color: #931b0b;
}
.About-text p {
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 18px;
  margin-bottom: 45px;
}
@media screen and (max-width: 960px) {
  .mission {
    text-align: justify;
    font-family: Lato;
    letter-spacing: 1px;
    line-height: 22px;
    font-size: 18px;
    /* width: 70%; */
    /* margin-left: 20%; */
  }
  .values {
    /* margin: 20px; */
    padding: 20px;
    font-family: Playfair;
    font-size: 2.3rem;
    color: #ea9999;
    letter-spacing: 1px;
    text-align: center;
    margin-left: 5%;
  }
  .about2 {
    /* font-family: OpenSans; */
    font-size: 3rem;
    color: grey;
    text-align: center;
    margin-left: 5%;
  }
  .About-text h1 {
    color: #ea9999;
    font-size: 60px;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  .About-text h5 {
    color: #ea9999;
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 2px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .About-text p {
    letter-spacing: 1px;
    line-height: 18px;
    font-size: 14px;
    margin-left: 10px;
  }
  .founders img {
    height: auto;
    width: 250px;
    padding-top: 10px;
  }
}
