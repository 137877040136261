.video-responsive {
    overflow: hidden;
    padding-bottom: 50.25%;
    position: relative;
    height: 0;
    
  }
  
  .video-responsive iframe {
    /* margin-bottom: -10px; */
    left: 9.5%;
    top: 17%;
    height: 80%;
    width: 80%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  