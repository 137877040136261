.singlePostDesc2 {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  /* margin-left: 50px; */
  /* text-align: justify; */
}

.BlogPost2 img {
  width: 100%;
  height: 100%;
}

.singlePostDesc2::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.BlogPost2 {
  width: 1130px;
  max-width: 95%;
  margin: 20px auto;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.heading-f2 {
  text-align: left;
  font-size: 20px;
  margin-left: 50px;
}
.About-text2 h3 {
  color: #ea9999;
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
  text-align: center;
}

.About-text2 h5 {
  color: #931b0b;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-left: 50px;
  text-align: center;
}
