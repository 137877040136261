/* .header {
  margin-top: 60px;
} */
body{
  overflow-x: hidden;
}
.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lora", serif;
  color: #444;
}

.headerTitleSm {
  position: absolute;
  top: 18%;
  font-size: 20px;
}

.headerTitleLg {
  position: absolute;
  top: 30%;
  font-size: 6rem;
  color: azure;
}

.header img{
  /* opacity: 0.9; */
  width: 100%;
  height: 450px;
  object-fit: cover;
}

@media screen and (max-width: 960px) {
  .headerTitleLg {
    position: absolute;
    top: 20%;
    font-size: 3rem;
    color: azure;
  }

  .header img{
    /* opacity: 0.9; */
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}