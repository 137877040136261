body {
  overflow-x: hidden;
}
.hero {
  /* background: url("./") center center/cover no-repeat; */
  background: #ea9999;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  /* margin-bottom: 30px; */
}

.container-impact {
  /* height: 100vh;  */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* .headerim{
  width: 100%;
  height: 100%;
} */

.row-text-center {
  margin: 20px;
  /* justify-content: space-between; */
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.about {
  /* font-family: OpenSans; */
  font-size: 5rem;
  color: white;
  margin: 29px;
}
/* .container {
  margin: 29px;
  justify-content: center;
} */
.title {
  font-weight: bold;
  text-align: center;
  font-family: Lato;
  letter-spacing: 1px;
  line-height: 80px;
  font-size: 24px;
  text-justify: auto;
  margin: 10px;
  /* margin-bottom: 10%; */
}
.contact {
  text-align: center;
  font-family: Lato;
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 20px;
  text-justify: center;
  /* margin-left: 40px; */
  /* margin: 10%; */
  margin: 0%;
  padding: 10px;
  /* text-indent: 0%; */
}
.headerim {
  width: 50%;
  height: 50%;
  margin-top: 0px;
  /* margin-left: -10px; */
  object-fit: cover;
}
.grid {
  margin-left: 15%;
  margin-top: -10px;
  align-self: center;
}
@media screen and (max-width: 960px) {
  .headerim {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    /* margin-left: -10px; */
    object-fit: cover;
  }
  .title {
    font-weight: bold;
    text-align: center;
    font-family: Lato;
    letter-spacing: 1px;
    line-height: 80px;
    font-size: 1rem;
    text-justify: auto;
    /* margin: 10px; */
    /* margin-bottom: 10%; */
  }
  .grid {
    margin-left: -10%;
    margin-top: -10px;
    align-self: center;
    overflow: hidden;
  }
  .contact {
    text-align: center;
    font-family: Lato;
    letter-spacing: 1px;
    line-height: 28px;
    font-size: 0.9rem;
    text-indent: -1px;
    /* text-justify: center; */
    /* margin-left: 40px; */
    /* margin: 10%; */
    margin: 0%;
  }

  .row-text-center {
    margin: 20px;
    /* justify-content: space-between; */
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
}
